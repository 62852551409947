import React from "react"
import {isChrome,isFirefox,isIeEdge,getBrowser} from "@tightrope/lpscripts/browserdetect"
import Styles from "./css/postDLsteps.module.scss"
import Data from "./data/data.json"
import Footerlinks from "@tightrope/footerlinks/footer"


class postDLsteps extends React.Component {
    constructor(props){
      super(props);
      this.state = {
        isChrome: isChrome(),
        isFirefox: isFirefox(),
        isIeEdge: isIeEdge(),
        display: 'none',
        pageData: props.data,
        data: Data
      }
    }

  showBrowsers(browsers){
    let display = false;
    if(getBrowser() === 'ie' && browsers.includes('ie')){
      display=true;
    }
    if(getBrowser() === 'firefox' && browsers.includes('firefox')){
      display=true;
    }
    if(getBrowser() === 'chrome' && browsers.includes('chrome')){
      display=true;
    }
    if(getBrowser() === 'edge' && browsers.includes('edge')){
      display=true;
    }
    if(getBrowser() === 'other' && browsers.includes('other')){
      display=true;
    }
    if(display)
      this.setState({display: 'block'});
    else
      this.setState({display: 'none'});
  }

  componentDidMount() {
    if(this.props.browsers){
      this.showBrowsers(this.props.browsers);
    }
    else{
      this.setState({display: 'block'});
    }
    if(this.props.data){
      this.props.data.then(data => {
        const instanceData = Object.assign(this.state.data, data);
        this.setState({ data: instanceData});
      this.state.isChrome = isChrome();
      this.state.isFirefox = isFirefox();
      this.state.isIeEdge = isIeEdge();

      if(isChrome()){
        const browserData = Object.assign(this.state.data, this.state.data.chrome);
        this.setState({data: browserData});
      }
      if(isFirefox()){
        const browserData = Object.assign(this.state.data, this.state.data.firefox);
        this.setState({data: browserData});
      }
          if(isIeEdge()){
              const browserData = Object.assign(this.state.data, this.state.data.edge);
              this.setState({data: browserData});
          }
      });
    }
  }
  render() {
    return (
      <>
      <div id = {Styles.postDL}>

<div className={Styles.wrap}>
 <div className={Styles.copy}>
   <h1>{this.state.data.headline1}<br/>
       {this.state.data.headline2}</h1>

   <div className={Styles.download}>
   <h3>{this.state.data.download}</h3>
   <a data-cy="cta" onClick = {() => window.triggerInstall()}>{this.state.data.dlLink}</a>
   </div>
 </div>

 <div className={Styles.gridContainer}>
   <div className={Styles.gridItem}>
     <div className={Styles.copyContainer}>
     <h2><span className={Styles.step}>01</span>{this.state.data.step1}</h2>
     <p>{this.state.data.step1copy}</p>
     </div>
     <img src={this.state.data.exeImg} alt="download file" />
   </div>
   <div className={`${Styles.gridItem} ${Styles.line}`}></div>
   <div className={Styles.gridItem}>
     <div className={Styles.copyContainer}>
     <h2><span className={Styles.step}>02</span>{this.state.data.step2}</h2>
     <p>{this.state.data.step2copy}</p>
     </div>
     <img src={this.state.data.installerImg} alt="install extension" />
   </div>
   <div className={`${Styles.gridItem} ${Styles.line}`}></div>
   <div className={Styles.gridItem}>
     <div className={Styles.copyContainer}>
     <h2><span className={Styles.step}>03</span>{this.state.data.step3}</h2>
     <p>{this.state.data.step3copy}</p>
     </div>
     <img src={this.state.data.functionImg} alt="product functionality"/>
   </div>
 </div>
</div>

        </div>
      <Footerlinks></Footerlinks>
    
      </>
    )
  }
}
export default postDLsteps
